<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <form class="mb-9" @submit.prevent="setData()">
                <div class="row justify-content-between align-items-end g-3 mb-5">
                    <div class="col-12 col-sm-auto col-xl-10">
                        <h2 class="mb-0" v-if="modo == 'Create'">New artwork</h2>
                        <h2 class="mb-0" v-else >Edit artwork</h2>
                    </div>
                    <div class="col-12 col-sm-auto col-xl-2">
                        <div class="d-flex">
                            <button type="submit" class="btn btn-primary px-5 w-100 text-nowrap">Save</button>
                        </div>
                    </div>
                </div>

                <div class="row g-5">
                    <div class="col-xl-12">
                        <div class="row gx-3 gy-4">
                            <div class="col-sm-12 col-md-12">
                                <div class="row">
                                    <div class="col-3">
                                        <div class="avatar avatar-5xl">
                                            <label for="featured" class="cursor-pointer" >
                                                <img class="rounded" :src="images.featured" alt="" />
                                            </label>
                                        </div>
                                        <input class="d-none" id="featured" type="file" @change="uploadImage($event,'featured')" />
                                    </div>
                                    
                                    <div class="col-9">
                                        <div class="row gx-3 gy-4" >
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-floating">
                                                    <input class="form-control" v-model="artwork.title" type="text" placeholder="Title" />
                                                    <label for="floatingEventInput">Title</label>
                                                </div>
                                            </div>  
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-floating">
                                                    <input class="form-control" v-model="artwork.link" type="text" placeholder="Link" />
                                                    <label for="floatingEventInput">Link</label>
                                                </div>
                                            </div>  
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-floating">
                                                    <input class="form-control" v-model="artwork.external_link" type="text" placeholder="External Link" />
                                                    <label for="floatingEventInput">External Link</label>
                                                </div>
                                            </div>  
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-floating">
                                                    <select class="form-select" v-model="artwork.contact" >
                                                        <option selected value="">Select option ... </option>
                                                        <option  v-for="(sale,x) in sales" :key="x" :value="sale.id">{{ sale.name }}</option>
                                                    </select>
                                                
                                                    <label for="floatingEventInput">Sales Contact</label>
                                                </div>
                                            </div>  
                                            <div class="col-sm-12 col-md-4">
                                                <div class="form-floating">
                                                    <input class="form-control" v-model="artwork.year" type="number" placeholder="Year" />
                                                    <label for="floatingEventInput">Year</label>
                                                </div>
                                            </div>  
                                            
                                            <div class="col-sm-12 col-md-4">
                                                <div class="form-floating">
                                                    <input class="form-control" v-model="artwork.price" type="number" placeholder="Price" />
                                                    <label for="floatingEventInput">Price</label>
                                                </div>
                                            </div>  
                                         
                                            
                                            <div class="col-sm-12 col-md-4">
                                                <div class="form-floating">
                                                    <textarea class="form-control" v-model="artwork.dimensions" placeholder="Dimensions" style="height:70px"></textarea>
                                                    <label for="floatingEventInput">Dimensions</label>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4">
                                <div class="form-floating">
                                    <select class="form-select" v-model="artwork.shipping_package" >
                                        <option selected value="">Select option ... </option>
                                        <option value="tube">Ships in a tube</option>
                                        <option value="box">Ships in a box</option>
                                        <option value="envelope">Ships in an envelope</option>
                                    </select>
                                    <label for="floatingEventInput">Shipping Package</label>
                                </div>
                            </div>  
                            <div class="col-sm-12 col-md-4">
                                <div class="form-floating">
                                    <select class="form-select" v-model="artwork.collection_id" >
                                        <option selected value="">Select option ... </option>
                                        <option  v-for="(collection,x) in collections" :key="x" :value="collection.id">{{ collection.name }} - ({{collection.is_private == 1 ? 'private' : 'on porfolio'}}) {{collection.is_individual == 1 ? 'individual' : 'no show'}}</option>
                                       
                                    </select>
                                
                                    <label for="floatingEventInput">Collection</label>
                                </div>
                            </div>  
                            <div class="col-sm-12 col-md-4">
                                <div class="form-floating">
                                    <select class="form-select" v-model="artwork.category_id" >
                                        <option selected value="">Select option ... </option>
                                        <option  v-for="(category,x) in categories" :key="x" :value="category.id">{{ category.name_en }}</option>
                                       
                                    </select>
                                
                                    <label for="floatingEventInput">Category</label>
                                </div>
                            </div>  
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <textarea class="form-control" v-model="artwork.media_en" placeholder="Media EN" style="height:150px"></textarea>
                                    <label for="floatingEventInput">Media EN</label>
                                </div>
                            </div>  
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <textarea class="form-control" v-model="artwork.media_es" placeholder="Media ES" style="height:150px"></textarea>
                                    <label for="floatingEventInput">Media ES</label>
                                </div>
                            </div>  
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <textarea class="form-control" v-model="artwork.description_en" placeholder="Description EN" style="height:150px"></textarea>
                                    <label for="floatingEventInput">Description EN</label>
                                </div>
                            </div>  
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <textarea class="form-control" v-model="artwork.description_es" placeholder="Description ES" style="height:150px"></textarea>
                                    <label for="floatingEventInput">Description ES</label>
                                </div>
                            </div>  
                            <div class="col-sm-12 col-md-6">
                                <div class="form-check">
                                    <input class="form-check-input" id="is_private" type="checkbox" v-model="artwork.is_private" true-value="1" false-value="0" />
                                    <label class="form-check-label" for="is_private">Private</label>
                                </div>
                            </div>  
                            <div class="col-sm-12 col-md-6">
                                <div class="form-check">
                                    <input class="form-check-input" id="is_Active" type="checkbox" v-model="artwork.is_available" true-value="1" false-value="0"  />
                                    <label class="form-check-label" for="is_Active">Available</label>
                                </div>
                            </div>  
                            <div class="col-sm-12 col-md-2">
                                <div class="form-check">
                                    <input class="form-check-input" id="has_print" type="checkbox" v-model="artwork.has_print" true-value="1" false-value="0"  />
                                    <label class="form-check-label" for="has_print">Has Prints</label>
                                </div>
                            </div>  
                            <div class="col-sm-12 col-md-8">
                                <div class="form-floating">
                                    <input class="form-control" v-model="artwork.external_link_print" type="text" placeholder="External Link Print" />
                                    <label for="floatingEventInput">External Link Print</label>
                                </div>
                            </div>  
                            <div class="col-sm-12 col-md-2">
                                <div class="form-floating">
                                    <input class="form-control" v-model="artwork.print_price" type="number" placeholder="Print Price" />
                                    <label for="floatingEventInput">Print Price</label>
                                </div>
                            </div>  
                        </div>
                        <div class="col-12">
                            <h5 class="text-center  text-uppercase pt-5">Extra Images</h5>
                            <div class="row">
                                <div class="col-2 p-3" v-for="n in 10" :key="n">
                                    <div class="avatar avatar-5xl">
                                        <label :for="'extra'+n" class="cursor-pointer" >
                                            <img class="rounded" :src="images['image_'+n]" :alt="'image_'+n" />
                                        </label>
                                    </div>
                                    <input class="d-none" :id="'extra'+n" type="file" @change="uploadImage($event,'image_'+n)" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </section>
</template>

<script>
import SideBar from '@/components/SideBarNav.vue'
import TopBar from '@/components/TopBarNav.vue'
import axios from 'axios'

export default {
    name:'artworkAdminComponent',
    components:{SideBar,TopBar},
    data(){
        return{
            artwork: {},
            collections:{},
            sales:{},
            categories:{},
            imageChanged: false,
            modo: this.$route.params.id > 0 ? 'Edit' : 'Create',
            images:{
                featured:'/assets/no-image.png',
                image_1:'/assets/no-image.png',
                image_2:'/assets/no-image.png',
                image_3:'/assets/no-image.png',
                image_4:'/assets/no-image.png',
                image_5:'/assets/no-image.png',
                image_6:'/assets/no-image.png',
                image_7:'/assets/no-image.png',
                image_8:'/assets/no-image.png',
                image_9:'/assets/no-image.png',
                image_10:'/assets/no-image.png'
            }
        }
    },
    created(){
        this.getCollections()
        this.getSales()
        this.getCategories()
        if(this.$route.params.id){
            this.getData()
        }
    },
    methods:{
        getCategories(){
             axios.get(this.$url+'/categories', {
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            }).then((res)=>{
                this.categories = res.data
            })
        },
        getCollections(){
             axios.get(this.$url+'/collections', {
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            }).then((res)=>{
                this.collections = res.data
            })
        },
        getSales(){
             axios.get(this.$url+'/sales', {
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            }).then((res)=>{
                this.sales = res.data
            })
        },
        getData(){
             axios.get(this.$url+'/artworkadmin/'+this.$route.params.id, {
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            }).then((res)=>{
                this.artwork = res.data
                this.images.featured = (res.data.featured == null? '/assets/no-image.png' : this.$url+'/'+res.data.featured)

                this.images.image_1    = (res.data.image_1  == null? '/assets/no-image.png' : this.$url+'/'+res.data.image_1 )
                this.images.image_2    = (res.data.image_2  == null? '/assets/no-image.png' : this.$url+'/'+res.data.image_2 )
                this.images.image_3    = (res.data.image_3  == null? '/assets/no-image.png' : this.$url+'/'+res.data.image_3 )
                this.images.image_4    = (res.data.image_4  == null? '/assets/no-image.png' : this.$url+'/'+res.data.image_4 )
                this.images.image_5    = (res.data.image_5  == null? '/assets/no-image.png' : this.$url+'/'+res.data.image_5 )
                this.images.image_6    = (res.data.image_6  == null? '/assets/no-image.png' : this.$url+'/'+res.data.image_6 )
                this.images.image_7    = (res.data.image_7  == null? '/assets/no-image.png' : this.$url+'/'+res.data.image_7 )
                this.images.image_8    = (res.data.image_8  == null? '/assets/no-image.png' : this.$url+'/'+res.data.image_8 )
                this.images.image_9    = (res.data.image_9  == null? '/assets/no-image.png' : this.$url+'/'+res.data.image_9 )
                this.images.image_10   = (res.data.image_10 == null? '/assets/no-image.png' : this.$url+'/'+res.data.image_10)
            })
        },
        setData(){
            let data
            var content = ''
            if( this.imageChanged == true && (this.images.featured != undefined && this.images.featured != '/assets/no-image.png') || 
                this.imageChanged == true && (this.images.image_1  != undefined && this.images.image_1 != '/assets/no-image.png') || 
                this.imageChanged == true && (this.images.image_2  != undefined && this.images.image_2 != '/assets/no-image.png') || 
                this.imageChanged == true && (this.images.image_3  != undefined && this.images.image_3 != '/assets/no-image.png') || 
                this.imageChanged == true && (this.images.image_4  != undefined && this.images.image_4 != '/assets/no-image.png') || 
                this.imageChanged == true && (this.images.image_5  != undefined && this.images.image_5 != '/assets/no-image.png') || 
                this.imageChanged == true && (this.images.image_6  != undefined && this.images.image_6 != '/assets/no-image.png') || 
                this.imageChanged == true && (this.images.image_7  != undefined && this.images.image_7 != '/assets/no-image.png') || 
                this.imageChanged == true && (this.images.image_8  != undefined && this.images.image_8 != '/assets/no-image.png') || 
                this.imageChanged == true && (this.images.image_9  != undefined && this.images.image_9 != '/assets/no-image.png') || 
                this.imageChanged == true && (this.images.image_10 != undefined && this.images.image_10 != '/assets/no-image.png') ){
                 
                content = 'multipart/form-data'
                data = new FormData()

                data.append('title',this.artwork.title)
                data.append('link',this.artwork.link)
                data.append('external_link',this.artwork.external_link)
                data.append('contact',this.artwork.contact)
                data.append('year',this.artwork.year)
                data.append('price',this.artwork.price)
                data.append('dimensions',this.artwork.dimensions)
                data.append('collection_id',this.artwork.collection_id)
                data.append('category_id',this.artwork.category_id)
                data.append('media_en',this.artwork.media_en)
                data.append('media_es',this.artwork.media_es)
                data.append('description_en',this.artwork.description_en)
                data.append('description_es',this.artwork.description_es)
                data.append('is_private',this.artwork.is_private)
                data.append('is_available',this.artwork.is_available)
                data.append('has_print',this.artwork.has_print)
                data.append('external_link_print',this.artwork.external_link_print)
                data.append('print_price',this.artwork.print_price)
                data.append('shipping_package',this.artwork.shipping_package)

                data.append('featured',this.artwork.featured)
                data.append('image_1',this.artwork.image_1)
                data.append('image_2',this.artwork.image_2)
                data.append('image_3',this.artwork.image_3)
                data.append('image_4',this.artwork.image_4)
                data.append('image_5',this.artwork.image_5)
                data.append('image_6',this.artwork.image_6)
                data.append('image_7',this.artwork.image_7)
                data.append('image_8',this.artwork.image_8)
                data.append('image_9',this.artwork.image_9)
                data.append('image_10',this.artwork.image_10)    
            }else{
             
                content = 'application/json'
                data = this.artwork
            }
            axios.post(this.$url+(this.modo == 'Create' ? '/artwork' : '/artwork/'+this.$route.params.id),data,{
                headers: {
                    'Content-Type' : content,
                    'Authorization' : this.$store.state.token
                }
            }).then(()=>{
                this.$router.push({name: 'artworks'})
            })
        },
        uploadImage($event,name){
            var image 
            if($event.target.files.length >= 1){
                image = $event.target.files[0]
            
                if(image.size <= 3000000){
                    if(image.type == 'image/jpeg' || image.type == 'image/png' || image.type == 'image/webp' || image.type == 'image/jpg'){
                        this.images[name] = URL.createObjectURL(image)
                        this.artwork[name] = image
                        this.imageChanged = true
                    }else{
                        this.$notify({
                            group: 'iasc',
                            title: 'Error',
                            text: 'El tipo de archivo no se permite',
                            type: 'danger'
                        });
                    }
                }else{
                    this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: 'El archivo debe pesar menos de 1Mb',
                        type: 'danger'
                    });
                }
            }
        }
    }
}
</script>
