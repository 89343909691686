import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store/index'
import jwt_decode from 'jwt-decode'

import LoginView from '@/views/LoginView.vue'
import CreateAccountView from '@/views/CreateAccountView.vue'

import ContentView from '@/views/Contents/Contents.vue'

import FeaturedView from '@/views/Featured/Featured.vue'
import FormFeaturedView from '@/views/Featured/FormFeatured.vue'

import SlidersView from '@/views/Sliders/Sliders.vue'
import StudioView from '@/views/BehindTheWork/BehindTheWork.vue'

import ArtworkView from '@/views/Artworks/Artworks.vue'
import FormArtworkView from '@/views/Artworks/FormArtwork.vue'

import ExhibitionView from '@/views/Exhibitions/Exhibitions.vue'
import FormExhibitionView from '@/views/Exhibitions/FormExhibition.vue'

import CollectionView from '@/views/Collections/Collections.vue'
import FormCollectionView from '@/views/Collections/FormCollection.vue'

import CategoryView from '@/views/Categories/Categories.vue'
import FormCategoryView from '@/views/Categories/FormCategory.vue'

import SalesView from '@/views/Sales/Sales.vue'
import FormSalesView from '@/views/Sales/FormSales.vue'

import NewView from '@/views/News/News.vue'
import FormNewView from '@/views/News/FormNew.vue'

import StudiesView from '@/views/Studies/Studies.vue'
import FormStudyView from '@/views/Studies/FormStudy.vue'

Vue.use(VueRouter)

const routes = [
  {path: '/login',name: 'login',component: LoginView, meta: {requiresAuth: false}},
  {path: '/create-account',name: 'createAccount',component: CreateAccountView, meta: {requiresAuth: false}},

  {path: '/',name: 'home',component: ArtworkView, meta: {requiresAuth: true}},
 
  {path: '/create-artwork',name: 'createArtwork',component: FormArtworkView, meta: {requiresAuth: true}},
  {path: '/create-category',name: 'createCategory',component: FormCategoryView, meta: {requiresAuth: true}},
  {path: '/create-collection',name: 'createColleciton',component: FormCollectionView, meta: {requiresAuth: true}},
  {path: '/create-exhibition',name: 'createExhibition',component: FormExhibitionView, meta: {requiresAuth: true}},
  {path: '/create-new',name: 'createNew',component: FormNewView, meta: {requiresAuth: true}},
  {path: '/create-featured',name: 'createFeatured',component: FormFeaturedView, meta: {requiresAuth: true}},
  {path: '/create-study',name: 'createStudy',component: FormStudyView, meta: {requiresAuth: true}},
  {path: '/create-sale',name: 'createSale',component: FormSalesView, meta: {requiresAuth: true}},

  {path: '/artwork/:id',name: 'editArtwork',component: FormArtworkView, meta: {requiresAuth: true}},
  {path: '/category/:id',name: 'editCategory',component: FormCategoryView, meta: {requiresAuth: true}},
  {path: '/collection/:id',name: 'editColleciton',component: FormCollectionView, meta: {requiresAuth: true}},
  {path: '/exhibition/:id',name: 'editExhibition',component: FormExhibitionView, meta: {requiresAuth: true}},
  {path: '/study/:id',name: 'editStudy',component: FormStudyView, meta: {requiresAuth: true}},
  {path: '/new/:id',name: 'editNew',component: FormNewView, meta: {requiresAuth: true}},
  {path: '/featured/:id',name: 'editFeatured',component: FormFeaturedView, meta: {requiresAuth: true}},
  {path: '/sales/:id',name: 'editSale',component: FormSalesView, meta: {requiresAuth: true}},

  {path: '/contents',name: 'contents',component: ContentView, meta: {requiresAuth: true}},
  {path: '/studio',name: 'studio',component: StudioView, meta: {requiresAuth: true}},
  {path: '/sliders',name: 'sliders',component: SlidersView, meta: {requiresAuth: true}},
  {path: '/studies',name: 'studies',component: StudiesView, meta: {requiresAuth: true}},
  {path: '/featureds',name: 'featured',component: FeaturedView, meta: {requiresAuth: true}},
  {path: '/artworks',name: 'artworks',component: ArtworkView, meta: {requiresAuth: true}},
  {path: '/categories',name: 'categories',component: CategoryView, meta: {requiresAuth: true}},
  {path: '/collections',name: 'collections',component: CollectionView, meta: {requiresAuth: true}},
  {path: '/exhibitions',name: 'exhibitions',component: ExhibitionView, meta: {requiresAuth: true}},
  {path: '/news',name: 'news',component: NewView, meta: {requiresAuth: true}},
  {path: '/sales',name: 'sales',component: SalesView, meta: {requiresAuth: true}},
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      top: 0, 
      behavior: 'smooth',
    }
  }
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(item=>item.meta.requiresAuth)){
    if(!store.state.token){
      next({name: 'login'})
    }
    
    try{
      jwt_decode(store.state.token)
    }catch(error){
      next({name: 'login'})
    }
    
    if(jwt_decode(store.state.token).exp*1000 <= new Date().getTime()){
      next({name: 'login'})
    }
    next()
  }else{
    next()
  }
})

export default router

