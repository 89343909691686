<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
           
            <form class="mb-9">
                <div class="row justify-content-between align-items-end g-3 mb-5">
                    <div class="col-12 col-sm-auto col-xl-10">
                    <h2 class="mb-0">Sliders</h2>
                    </div>
                    <div class="col-12 col-sm-auto col-xl-2"></div>
                </div>
                <div class="row g-5">
                    <div class="col-xl-12">
                        <div class="row gx-3 gy-4">
                            <div class="card" id="table" >
                                <div class="card-header">
                                    <div class="row align-items-center">
                                        <div class="col">
                                            <div class="avatar avatar-5xl ">
                                                <label for="new-slide" class="cursor-pointer" >
                                                    <img class="rounded-soft" :src="img_src" alt="IMAGE" />
                                                </label>
                                            </div>
                                            <input class=" d-none" id="new-slide" type="file"  @change="uploadImage($event)" />
                                        </div>
                                    </div> 
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-sm fs--1 mb-0">
                                        <thead>
                                        <tr>
                                            <th class="sort border-top" ></th>
                                            <th class="sort text-end align-middle pe-0 border-top" scope="col">
                                                <span class="nav-link-icon"><span data-feather="cog"></span></span>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(est,x) in sliders" :key="x">
                                                <td class="item-nombre align-middle">
                                                    <img class="rounded-soft" :src="$url+'/'+est.image" alt="" style="width:200px" />
                                                    <p>id:{{est.id}} - created at:{{est.createdAt}}</p>
                                                </td>
                                                <td class="align-middle white-space-nowrap text-end pe-0">
                                                    <div class="font-sans-serif btn-reveal-trigger position-static">
                                                        <button class="btn btn-sm dropdown-toggle dropdown-caret-none transition-none btn-reveal fs--2" type="button" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false" data-bs-reference="parent"><svg class="svg-inline--fa fa-ellipsis fs--2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ellipsis" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M120 256C120 286.9 94.93 312 64 312C33.07 312 8 286.9 8 256C8 225.1 33.07 200 64 200C94.93 200 120 225.1 120 256zM280 256C280 286.9 254.9 312 224 312C193.1 312 168 286.9 168 256C168 225.1 193.1 200 224 200C254.9 200 280 225.1 280 256zM328 256C328 225.1 353.1 200 384 200C414.9 200 440 225.1 440 256C440 286.9 414.9 312 384 312C353.1 312 328 286.9 328 256z"></path></svg><!-- <span class="fas fa-ellipsis-h fs--2"></span> Font Awesome fontawesome.com --></button>
                                                        <div class="dropdown-menu dropdown-menu-end py-2">
                                                            <a href="#" class="dropdown-item text-danger" @click="borrar(est)">Delete</a>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </section>
  </template>
  
  <script>
  // @ is an alias to /src
  import SideBar from '@/components/SideBarNav.vue'
  import TopBar from '@/components/TopBarNav.vue'
  import axios from 'axios'
  import MagnifyIcon from 'vue-material-design-icons/Magnify.vue'

  export default {
    name: 'newsView',
    components:{SideBar,TopBar,MagnifyIcon},
    data(){
        return{
            sliders:[],
            img_src: '/assets/no-image.png'
        }
    },
    created(){
        this.getData()
    },
    methods:{
        getData(){
             axios.get(this.$url+'/sliders',{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                this.sliders = res.data
            }).catch((err)=>{
                console.log(err)
            })
        },
        borrar(data){
            axios.post(this.$url+'/sliders/delete/'+data.id,{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                this.sliders = this.sliders.filter(e=>e.id != data.id)
            }).catch((err)=>{
                console.log(err)
            })
        },
        uploadImage($event){
            var image 
            if($event.target.files.length >= 1){
                image =  $event.target.files[0]
            }
            
            if(image.size <= 3000000){
                if( image.type == 'image/jpg' || image.type == 'image/jpeg' || image.type == 'image/png'){
                    this.img_src = URL.createObjectURL(image)
                    let fm = new FormData()
                    fm.append('foto',image) 
                    axios.post(this.$url+'/sliders',fm,{
                        headers: {
                            'Content-Type' : 'multipart/form-data',
                            'Authorization' : this.$store.state.token
                        }
                    }).then((res)=>{
                        console.log(res)
                    })
                }else{
                    this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: 'El tipo de archivo no se permite',
                        type: 'danger'
                    });
                    this.img_src = undefined
                }
            }else{
                this.$notify({
                    group: 'iasc',
                    title: 'Error',
                    text: 'El archivo debe pesar menos de 1Mb',
                    type: 'danger'
                });
                this.img_src = undefined
            }
        }
    }
  }
  </script>
  
                    